import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from '@material-ui/core';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AuthLayout from './AuthLayout';
import { useMutation, useQuery } from 'react-query';
import UserApiHook from '../../hooks/UserApiHook';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomTextField } from '../../components/TextField';
import TransactionApiHook from '../../hooks/TransactionApiHook';
import HistoryApiHook from '../../hooks/HistoryApiHook';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckBox } from '@material-ui/icons';
import useThemeHook from '../../hooks/ThemeHook';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomButton } from '../../components/Button';
import { afterValidate } from '../../utils/CommonLogic';
import { getErrorMsz } from '../../utils/validator';

function UserDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { xs_layout } = useThemeHook();

  const { getUser, getUserById } = UserApiHook();
  const { getTransactionById } = TransactionApiHook();
  const { getHistoryByUserId } = HistoryApiHook();

  const { state } = location;
  const { userIdFromParent = null } = state || {};

  const [currentUserId, setCurrentUserId] = useState(userIdFromParent);
  const [userCount, setUserCount] = useState(0);
  const [userByIdData, setUserByIdData] = useState({});
  const [historyByIdData, setHistoryByIdData] = useState([]);
  const [transactionByIdData, setTransactionByIdData] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState('none');
  const [userDataArr, setUserDataArr] = React.useState([]);
  const [balancecheckRange, setBalancecheckRange] = React.useState([100, 500]);
  const [submitFlag, setsubmitFlag] = useState(false);
  const [topActionArr, setTopActionArr] = useState([
    { label: 'All', checked: true },
    { label: 'Balance Check', checked: false },
    { label: 'Update', checked: false },
    { label: 'Blank FCM', checked: false },
  ]);

  const [notificationObj, setNotificationObj] = useState({
    title: '',
    body: '',
    userIdArr: [],
  });

  const [CheckedOption, setCheckedOption] = useState([]);
  const [toggleDrawer, setToggleDrawer] = useState(false);

  const userArrCheck = [
    '_id',
    'notificationData',
    'withdrawalLimitArr',
    '__v',
    'password',
    'bioKey',
    'withdrawalLimit',
  ];

  const transArr = [
    'amount',
    'completedStatus',
    'date',
    'status',
    'transactionRefId',
  ];
  const historyArr = [
    'playhistoryId',
    'qId',
    'date',
    'amount',
    'betPoints',
    'status',
  ];

  const {
    data: userArr,
    isLoading: userDataLoader,
    refetch: userListRefetch,
  } = useQuery(['GetUser'], () => getUser(), {
    enabled: false,
  });

  useEffect(() => {
    userListRefetch();
  }, []);

  useEffect(() => {
    currentUserId != null && getUserByIdMutate(currentUserId);
    currentUserId != null && getTransactionByIdMutate(currentUserId);
    currentUserId != null && getHistoryByUserIdMutate(currentUserId);
  }, [currentUserId]);

  const onSuccessUser = (data) => {
    setUserByIdData(data?.data?.data?.data[0]);
  };
  const onErrorUser = (error) => {};

  const onSuccessHistory = (data) => {
    console.log(data?.data?.data?.data);
    setHistoryByIdData(data?.data?.data?.data);
  };
  const onSuccessTrans = (data) => {
    console.log(data?.data);
    setTransactionByIdData(data?.data?.data);
  };

  const {
    mutate: getHistoryByUserIdMutate,
    isLoading: getHistoryByUserIdLoader,
  } = useMutation(getHistoryByUserId, {
    onSuccess: onSuccessHistory,
    onError: onErrorUser,
  });
  const { mutate: getUserByIdMutate, isLoading: getUserByIdLoader } =
    useMutation(getUserById, {
      onSuccess: onSuccessUser,
      onError: onErrorUser,
    });

  const {
    mutate: getTransactionByIdMutate,
    isLoading: getTransactionByIdLoader,
  } = useMutation(getTransactionById, {
    onSuccess: onSuccessTrans,
    onError: onErrorUser,
  });

  useEffect(() => {
    setUserDataArr(userArr?.data?.data?.data);
  }, [userArr]);

  useEffect(() => {
    CheckedOption.includes('all') && setUserDataArr(userArr?.data?.data?.data);
    CheckedOption.includes('blank fcm') &&
      setUserDataArr(userArr?.data?.data?.data.filter((o) => o.fcmToken == ''));
    CheckedOption.includes('update') &&
      setUserDataArr(
        userArr?.data?.data?.data.filter((o) => o.version == userCount)
      );
    CheckedOption.includes('balance check') &&
      setUserDataArr(
        userArr?.data?.data?.data.filter(
          (o) =>
            o.points >= balancecheckRange[0] && o.points <= balancecheckRange[1]
        )
      );
  }, [CheckedOption, userCount, balancecheckRange]);

  useEffect(() => {
    setUserCount(0);
  }, [selectedValue]);

  const handleChange = (event, label) => {
    const localArr = topActionArr.map((o) => ({
      ...o,
      checked: o.label == label ? true : false,
    }));

    const checkedOption = localArr.filter((o) => o.checked);
    setCheckedOption(checkedOption.map((o) => o.label.toLowerCase()));
    setTopActionArr(localArr);
  };

  const submitHandler = () => {
    setsubmitFlag(true);
    afterValidate(afterValidateCallBack);
  };

  const afterValidateCallBack = (second) => {
    setsubmitFlag(false);
    // action === 'add'
    //   ? registerMutate(drawerData)
    //   : updateUserMutate(drawerData);
  };

  return (
    <AuthLayout>
      <Box style={{ maxHeight: 'calc(-89px + 100vh)', overflow: 'hidden' }}>
        <Grid justifyContent="center" style={{}} container>
          {!xs_layout && (
            <Grid
              item
              xs={12}
              sm={2}
              lg={1.5}
              style={{
                backgroundColor: '#ddd',
              }}
            >
              {userDataLoader && (
                <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
                  <Backdrop
                    style={{
                      color: '#fff',
                      zIndex: 2,
                    }}
                    open={true}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
              )}

              <Typography
                variant="h6"
                style={{ padding: 8, textAlign: 'center' }}
              >
                Total User: {userDataArr?.length}
              </Typography>
              <Box style={{ padding: 5 }}>
                <CustomTextField
                  fullWidth
                  type="text"
                  placeholder="UserId"
                  variant="filled"
                  value={currentUserId}
                  onChange={(event) => {
                    setCurrentUserId(event.target.value);
                  }}
                />
              </Box>
              {CheckedOption.includes('balance check') && (
                <Box style={{ padding: 5 }}>
                  <Slider
                    getAriaLabel={() => 'Temperature range'}
                    value={balancecheckRange}
                    onChange={(e, value) => setBalancecheckRange(value)}
                    valueLabelDisplay="auto"
                    min={0}
                    step={100}
                    max={1000}
                  />
                </Box>
              )}
              {CheckedOption.includes('update') && (
                <Box style={{ padding: 5 }}>
                  <CustomTextField
                    fullWidth
                    type="text"
                    placeholder="UserId"
                    variant="filled"
                    value={userCount}
                    onChange={(event) => {
                      setUserCount(event.target.value);
                    }}
                  />
                </Box>
              )}
              <Box style={{ overflow: 'auto', height: 'calc(100vh - 225px)' }}>
                {userDataArr?.length > 0 &&
                  userDataArr.map((o) => {
                    return (
                      <Box
                        sx={{
                          marginY: 1,
                          backgroundColor:
                            o.userId == currentUserId ? '#fff' : '',
                        }}
                        onClick={() => setCurrentUserId(o.userId)}
                      >
                        <Typography style={{ padding: 8, textAlign: 'center' }}>
                          {o.userId}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={10}
            lg={10.5}
            style={{
              backgroundColor: '#fff',
              padding: 16,
              overflow: 'auto',
              height: 'calc(100vh - 80px)',
            }}
          >
            {(getUserByIdLoader ||
              getTransactionByIdLoader ||
              getHistoryByUserIdLoader) && (
              <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
                <Backdrop
                  style={{
                    color: '#fff',
                    zIndex: 2,
                  }}
                  open={true}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Box>
            )}
            <Grid justifyContent="center" style={{}} container>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: '#ddd',
                }}
              >
                <Box sx={{ width: '100%', textAlign: 'center' }}>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      {topActionArr.map((o) => {
                        return (
                          <FormControlLabel
                            value={o.label.toLowerCase()}
                            control={<Checkbox checked={o.checked} />}
                            label={o.label}
                            onChange={(e) => handleChange(e, o.label)}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                  <Typography onClick={() => setToggleDrawer(true)}>
                    Send Notification
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Grid container style={{ justifyContent: 'space-between' }}>
                  <Grid item xs={12} sm={6}>
                    User details
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      onClick={() =>
                        navigate('/admincontrol/user', {
                          state: {
                            userIdFromParent: currentUserId,
                          },
                        })
                      }
                    >
                      <Typography style={{ textAlign: 'right' }}>
                        Go to User Table
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  {userByIdData &&
                    Object.keys(userByIdData).length > 0 &&
                    Object.keys(userByIdData).map((o) => {
                      return (
                        !userArrCheck.includes(o) && (
                          <Grid item xs={12} sm={4}>
                            <CustomTextField
                              fullWidth
                              type="text"
                              inputLabel={o}
                              placeholder={o}
                              variant="filled"
                              value={
                                typeof userByIdData[o] == 'object'
                                  ? JSON.stringify(userByIdData[o])
                                  : userByIdData[o]
                              }
                              // value={drawerData.points}
                              disabled={true}
                              // onChange={(event) => {
                              //   setDrawerData({
                              //     ...drawerData,
                              //     points: event.target.value,
                              //   });
                              // }}
                            />
                          </Grid>
                        )
                      );
                    })}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Grid container style={{ justifyContent: 'space-between' }}>
                  <Grid item xs={12} sm={6}>
                    Play History : {historyByIdData.length}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      onClick={() =>
                        navigate('/admincontrol/history', {
                          state: {
                            userIdFromParent: currentUserId,
                          },
                        })
                      }
                    >
                      <Typography style={{ textAlign: 'right' }}>
                        Go to History Table
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: 'column' }}>
                {historyByIdData?.length > 0 &&
                  historyByIdData.map((historyByIdObj) => {
                    return (
                      <Box mb={2}>
                        <Grid container spacing={1}>
                          {Object.keys(historyByIdObj).map((o) => {
                            return (
                              historyArr.includes(o) && (
                                <Grid item xs={12} sm={2}>
                                  <Box mb={1}>
                                    <Typography
                                      variant="body1"
                                      component="span"
                                    >
                                      <Box fontWeight="bold">{o}</Box>
                                    </Typography>
                                    <Typography variant="body1" noWrap={true}>
                                      <Box
                                        onClick={() =>
                                          o == 'qId' &&
                                          navigate('/admincontrol/question', {
                                            state: {
                                              userIdFromParent:
                                                historyByIdObj[o],
                                            },
                                          })
                                        }
                                      >
                                        {historyByIdObj[o]}
                                      </Box>
                                    </Typography>
                                  </Box>
                                </Grid>
                              )
                            );
                          })}
                        </Grid>
                      </Box>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Grid container style={{ justifyContent: 'space-between' }}>
                  <Grid item xs={12} sm={6}>
                    Transactions {transactionByIdData.length}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      onClick={() =>
                        navigate('/admincontrol/transaction', {
                          state: {
                            userIdFromParent: currentUserId,
                          },
                        })
                      }
                    >
                      <Typography style={{ textAlign: 'right' }}>
                        Go to Transaction Table
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails style={{ flexDirection: 'column' }}>
                {transactionByIdData?.length > 0 &&
                  transactionByIdData.map((historyByIdObj) => {
                    return (
                      <Box mb={2}>
                        <Grid container spacing={1}>
                          {Object.keys(historyByIdObj).map((o) => {
                            return (
                              transArr.includes(o) && (
                                <Grid item xs={12} sm={3}>
                                  <CustomTextField
                                    fullWidth
                                    type="text"
                                    inputLabel={o}
                                    placeholder={o}
                                    variant="filled"
                                    value={historyByIdObj[o]}
                                    // value={drawerData.points}
                                    disabled={true}
                                    // onChange={(event) => {
                                    //   setDrawerData({
                                    //     ...drawerData,
                                    //     points: event.target.value,
                                    //   });
                                    // }}
                                  />
                                </Grid>
                              )
                            );
                          })}
                        </Grid>
                      </Box>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Box>
      {toggleDrawer && (
        <Drawer
          open={true}
          anchor="right"
          onClose={() => setToggleDrawer(false)}
        >
          <Box sx={[xs_layout ? { p: 1 } : { p: 2, maxWidth: '768px' }]}>
            <Grid container style={{}} spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ textJustify: 'center' }}>
                  <ArrowBackIcon
                    onClick={() => setToggleDrawer(false)}
                    style={{ marginRight: 10 }}
                  />
                  Send Notification
                </Typography>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  type="text"
                  required
                  inputLabel="Title"
                  placeholder="Title"
                  variant="filled"
                  value={notificationObj.title}
                  onChange={(event) => {
                    setNotificationObj({
                      ...notificationObj,
                      title: event.target.value,
                    });
                  }}
                  error={
                    submitFlag &&
                    getErrorMsz('blankString', notificationObj.title) != ''
                  }
                  errorMsz={getErrorMsz('blankString', notificationObj.title)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="Body"
                  required
                  placeholder="Body"
                  variant="filled"
                  value={notificationObj.body}
                  onChange={(event) => {
                    setNotificationObj({
                      ...notificationObj,
                      body: event.target.value,
                    });
                  }}
                  error={
                    submitFlag &&
                    getErrorMsz('blankString', notificationObj.body) != ''
                  }
                  errorMsz={getErrorMsz('blankString', notificationObj.body)}
                />
              </Grid>
              <Grid item xs={12}>
                <Box style={{ width: '100%' }}>
                  <Typography>UserCount:{userDataArr.length}</Typography>
                </Box>
                {userDataArr.map((o) => {
                  return (
                    <Box style={{ width: 100, display: 'inline-block' }}>
                      <Typography variant="span">
                        {o.userId}
                        {', '}
                      </Typography>
                    </Box>
                  );
                })}
              </Grid>

              <Grid
                item
                xs={12}
                justifyContent="center"
                sx={{ display: 'flex' }}
              >
                <CustomButton
                  btnText={`Send`}
                  color="primary"
                  variant="contained"
                  className="minWidth240"
                  onClick={submitHandler}
                />
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      )}
    </AuthLayout>
  );
}

export default UserDetails;
