import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import AuthLayout from '../AuthLayout';
import { useMutation, useQuery } from 'react-query';
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import { CustomTextField } from '../../../components/TextField';
import { getErrorMsz } from '../../../utils/validator';
import { afterValidate } from '../../../utils/CommonLogic';
import { CustomButton } from '../../../components/Button';
import { CustomSnackbar } from '../../../components/CustomSnackbar';
import useUnAuthCrudApiHook from '../../../hooks/UnAuthApiHook';
import UserApiHook from '../../../hooks/UserApiHook';
import useThemeHook from '../../../hooks/ThemeHook';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useConfigrutionAPIHook from '../../../hooks/ConfigrutionAPIHook';
import { useLocation, useNavigate } from 'react-router-dom';

const User = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { userIdFromParent = '' } = state || {};
  const { getUser, updateUserById, deleteUserById } = UserApiHook();
  const { signUp } = useUnAuthCrudApiHook();
  const { xs_layout } = useThemeHook();

  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [snakeBarProps, setSnakeBarProps] = useState({});
  const [submitFlag, setsubmitFlag] = useState(false);
  const [action, setAction] = useState('');
  const [deleteConfirmFlag, setDeleteConfirmFlag] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [currentLimit, setCurrentLimit] = useState(null);
  const [currentPoints, setCurrentPoints] = useState(null);
  const [pointBasedQuestion, setPointBasedQuestion] = useState(null);

  const initialDrawerData = {
    userId: 0,
    username: '',
    email: '',
    mobilenumber: '',
    points: '100',
    role: 'guest',
    instruction: false,
    onHold: false,
    paymentDetail: { upiId: '' },
  };
  const [drawerData, setDrawerData] = useState(initialDrawerData);

  const updatedPointBasedQuestion = [
    { point: '0-200', level: ['Tuff', 'Tuff'] },
    { point: '201-500', level: ['Tuff', 'Tuff'] },
    { point: '501', level: ['Tuff'] },
  ];

  const dropdownArr = [
    { label: 'Admin', value: 'admin' },
    { label: 'Guest', value: 'guest' },
  ];

  const toggleArr = [
    { label: 'True', value: true },
    { label: 'False', value: false },
  ];

  const {
    data: userArr,
    isLoading: userDataLoader,
    refetch: userListRefetch,
  } = useQuery(['GetUser'], () => getUser(), {
    enabled: true,
  });

  const { getConfig } = useConfigrutionAPIHook();
  const {
    data: configArr,
    isLoading: configLoader,
    refetch: configRefetch,
  } = useQuery(['getConfig'], () => getConfig(), {
    enabled: true,
  });

  const planArr =
    configArr?.data?.data?.subcriptionPlan.map((o) => ({
      ...o,
      value: o.subscription,
      label: o.subscription,
    })) ?? [];

  const { mutate: registerMutate, isLoading: registerLoader } = useMutation(
    signUp,
    {
      onSuccess: (data) => onSuccessRegister(data),
      onError: (data) => onErrorResgister(data),
    }
  );
  const { mutate: updateUserMutate, isLoading: updateUserLoader } = useMutation(
    updateUserById,
    {
      onSuccess: (data) => onSuccessRegister(data),
      onError: (data) => onErrorResgister(data),
    }
  );
  const { mutate: deleteUserMutate, isLoading: deleteUserLoader } = useMutation(
    deleteUserById,
    {
      onSuccess: (data) => onSuccessRegister(data),
      onError: (data) => onErrorResgister(data),
    }
  );

  const onSuccessRegister = (data) => {
    if (data.data.status) {
      setToggleDrawer(false);
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'success',
      });
      userListRefetch();
    } else {
      setSnakeBarProps({
        snackbarFlag: true,
        msz: data.data.message,
        type: 'error',
      });
    }
  };

  const onErrorResgister = (data) => {
    console.log('data', data);
    // setToggleDrawer(false);
  };

  const submitHandler = () => {
    setsubmitFlag(true);
    afterValidate(afterValidateCallBack);
  };

  const afterValidateCallBack = (second) => {
    setsubmitFlag(false);
    action === 'add'
      ? registerMutate(drawerData)
      : updateUserMutate(drawerData);
  };

  const userData = userArr?.data?.data?.data ?? [];

  const handleCancel = () => {
    setDeleteConfirmFlag(false);
  };

  const handleOk = () => {
    setDeleteConfirmFlag(false);
    deleteUserMutate(currentId);
  };

  const toggleBonus = (checked) => {
    setDrawerData({
      ...drawerData,
      bonus: checked,
      points: !checked
        ? currentPoints
        : parseInt(drawerData.points) + parseInt(drawerData.bonusAmt),

      pointBasedQuestion: checked
        ? updatedPointBasedQuestion
        : pointBasedQuestion,
    });
  };
  const changePlan = (currentSubs) => {
    const currentPlan = planArr.filter((o) => o.value == currentSubs);

    setDrawerData({
      ...drawerData,
      subscription: currentSubs,
      points:
        currentSubs == drawerData.subscription
          ? currentPoints
          : drawerData.bonus
          ? parseInt(drawerData.bonusAmt ?? 0) +
            parseInt(currentPlan[0].walletPoint)
          : parseInt(currentPlan[0].walletPoint),

      defaultQuestionAttemptLimit:
        currentSubs == drawerData.subscription
          ? currentLimit
          : currentPlan[0].defaultQuestionAttemptLimit,
    });
  };

  return (
    <AuthLayout>
      {registerLoader ||
        updateUserLoader ||
        deleteUserLoader ||
        (userDataLoader && (
          <Box sx={{ p: 2, width: '400px', display: 'flex' }}>
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        ))}
      <Box sx={{ width: xs_layout ? '100%' : '90%', margin: '20px auto' }}>
        <Box mb={1} textAlign={'center'}>
          <Typography variant="body1" component="span">
            UserCount :{userData.length}
          </Typography>
        </Box>
        <MaterialTable
          columns={[
            {
              title: 'Id',
              field: 'userId',
              render: (rowData) => {
                return (
                  <Box
                    onClick={() =>
                      navigate('/admincontrol/userdetails', {
                        state: {
                          userIdFromParent: rowData.userId,
                        },
                      })
                    }
                  >
                    <Typography style={{ textDecorationLine: 'underline' }}>
                      {rowData.userId}
                    </Typography>
                  </Box>
                );
              },
            },

            // {
            //   title: 'Email',
            //   field: 'email',
            //   cellStyle: { width: 250 },
            //   render: (rowData) => (
            //     <Typography noWrap>{rowData.email}</Typography>
            //   ),
            // },
            {
              title: 'Mobile',
              field: 'mobilenumber',
            },
            {
              title: 'Name',
              field: 'username',

              render: (rowData) => (
                <Typography noWrap>{rowData.username}</Typography>
              ),
            },
            { title: 'Points', field: 'points' },
            {
              title: 'Plan',
              field: 'subscription',
            },
            {
              title: 'Page',
              field: 'lastVisitedPage',
            },
            {
              title: 'Time',
              field: 'updatedAt',
              render: (rowData) => (
                <Typography noWrap>{rowData.updatedAt}</Typography>
              ),
            },

            { title: 'Role', field: 'role' },
            { title: 'Bonus', field: 'bonus' },
            { title: 'Version', field: 'version' },
            // {
            //   title: 'FCM',
            //   field: 'fcmToken',
            //
            //   render: (rowData) => (
            //     <Typography noWrap>{rowData.fcmToken}</Typography>
            //   ),
            // },
          ]}
          data={userData}
          title="User"
          onRowClick={(event, rowData, togglePanel) => {
            setAction('edit');
            setCurrentSubscription(rowData.subscription);
            setCurrentPoints(rowData.points);
            setCurrentLimit(rowData.defaultQuestionAttemptLimit);
            setPointBasedQuestion(rowData.pointBasedQuestion);

            setDrawerData({
              ...rowData,
              userId: rowData.userId,
              username: rowData.username ?? '',
              email: rowData.email ?? '',
              mobilenumber: rowData.mobilenumber,
              points: rowData.points,
              role: rowData.role,
            });
            setToggleDrawer(true);
          }}
          actions={[
            {
              icon: 'add',
              tooltip: 'Add User',
              isFreeAction: true,
              onClick: (event) => {
                setAction('add');
                setDrawerData(initialDrawerData);
                setToggleDrawer(true);
              },
            },
            {
              icon: 'refresh',
              tooltip: 'Refresh',
              isFreeAction: true,
              onClick: (event) => {
                userListRefetch();
              },
            },
            (rowData) => ({
              icon: 'delete',
              tooltip: 'Delete User',
              onClick: (event, rowData) => {
                setDeleteConfirmFlag(true);
                setCurrentId(rowData.userId);
              },
              disabled: rowData.birthYear < 2000,
            }),
          ]}
          options={{
            actionsColumnIndex: 0,
            // filtering: true,

            pageSize: 10,
            search: true,
            searchText: '' + userIdFromParent,
          }}
        />
      </Box>

      {toggleDrawer && (
        <Drawer
          open={true}
          anchor="right"
          onClose={() => setToggleDrawer(false)}
        >
          <Box sx={[xs_layout ? { p: 1 } : { p: 2, width: '1080px' }]}>
            <Grid container style={{}} spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ textJustify: 'center' }}>
                  <ArrowBackIcon
                    onClick={() => setToggleDrawer(false)}
                    style={{ marginRight: 10 }}
                  />
                  {`${action === 'edit' ? 'Update' : 'Add'}  User`}
                  {`      -     ${drawerData.userId}`}
                </Typography>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={1}>
                  <Typography variant="body1" component="span">
                    <Box fontWeight="bold">Bonus</Box>
                  </Typography>
                </Box>
                <Checkbox
                  checked={drawerData.bonus}
                  label="Bonus"
                  onChange={(event) => {
                    toggleBonus(event.target.checked);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="bonusAmt"
                  placeholder="bonusAmt"
                  variant="filled"
                  value={drawerData.bonusAmt ?? 0}
                  // value={drawerData.bonusAmt}
                  disabled={action === 'add'}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      bonusAmt: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  fullWidth
                  select
                  selectArr={planArr}
                  selectDefaultOption={planArr.filter(
                    (o) => o.value == drawerData.subscription
                  )}
                  type="text"
                  inputLabel="subscription"
                  placeholder="subscription"
                  variant="filled"
                  value={drawerData.subscription}
                  onChange={(event) => {
                    changePlan(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="Points"
                  placeholder="Points"
                  variant="filled"
                  value={drawerData.points}
                  // value={drawerData.points}
                  disabled={action === 'add'}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      points: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="defaultQuestionAttemptLimit"
                  placeholder="defaultQuestionAttemptLimit"
                  variant="filled"
                  value={drawerData.defaultQuestionAttemptLimit}
                  disabled={action === 'add'}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      defaultQuestionAttemptLimit: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="PointBasedQuestion"
                  placeholder="PointBasedQuestion"
                  variant="filled"
                  value={JSON.stringify(drawerData.pointBasedQuestion)}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      pointBasedQuestion: JSON.parse(event.target.value),
                    });
                  }}
                  multiline
                  maxRows={3}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="pointToInr"
                  placeholder="pointToInr"
                  variant="filled"
                  value={drawerData.pointToInr}
                  // value={drawerData.pointToInr}
                  disabled={action === 'add'}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      pointToInr: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  select
                  selectArr={toggleArr}
                  selectDefaultOption={toggleArr.filter(
                    (o) => o.value == drawerData.onHold
                  )}
                  type="text"
                  inputLabel="OnHold"
                  placeholder="OnHold"
                  variant="filled"
                  value={drawerData.onHold}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      onHold: event.target.value,
                    });
                  }}
                />
              </Grid>{' '}
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  select
                  selectArr={dropdownArr}
                  selectDefaultOption={dropdownArr.filter(
                    (o) => o.value == drawerData.email.role
                  )}
                  type="text"
                  inputLabel="Role"
                  placeholder="Role"
                  variant="filled"
                  value={drawerData.role}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      role: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  select
                  selectArr={toggleArr}
                  selectDefaultOption={toggleArr.filter(
                    (o) => o.value == drawerData.instruction
                  )}
                  type="text"
                  inputLabel="Instruction"
                  placeholder="Instruction"
                  variant="filled"
                  value={drawerData.instruction}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      instruction: event.target.value,
                    });
                  }}
                />
              </Grid>{' '}
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  inputLabel="Mobile Number"
                  type="text"
                  placeholder="Mobile Number"
                  variant="filled"
                  required
                  disabled={action === 'edit'}
                  value={drawerData.mobilenumber}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      mobilenumber: event.target.value,
                    });
                  }}
                  error={
                    submitFlag &&
                    getErrorMsz('mobilenumber', drawerData.mobilenumber) != ''
                  }
                  errorMsz={getErrorMsz(
                    'mobilenumber',
                    drawerData.mobilenumber
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="Email"
                  placeholder="Email"
                  variant="filled"
                  disabled={action === 'edit'}
                  value={drawerData.email}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      email: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  type="text"
                  inputLabel="Name"
                  placeholder="Name"
                  disabled={action === 'edit'}
                  variant="filled"
                  value={drawerData.username}
                  onChange={(event) => {
                    setDrawerData({
                      ...drawerData,
                      username: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  inputLabel="FCM"
                  type="text"
                  placeholder="FCM"
                  variant="filled"
                  disabled={true}
                  value={drawerData.fcmToken}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  inputLabel="UPIID"
                  type="text"
                  placeholder="UPIID"
                  variant="filled"
                  disabled={true}
                  value={drawerData?.paymentDetail?.upiId ?? 'NA'}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  fullWidth
                  inputLabel="Version"
                  type="text"
                  placeholder="Version"
                  variant="filled"
                  disabled={true}
                  value={drawerData?.version ?? 'NA'}
                />
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                sx={{ display: 'flex' }}
              >
                <CustomButton
                  btnText={`${action === 'edit' ? 'Update' : 'Add'} User`}
                  color="primary"
                  variant="contained"
                  className="minWidth240"
                  onClick={submitHandler}
                />
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      )}
      {deleteConfirmFlag && (
        <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth="xs"
          open={true}
        >
          <DialogTitle>Do you want to delete this user?</DialogTitle>
          <DialogContent dividers>
            <Typography fontWeight="700">UserId {currentId}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleOk}>Ok</Button>
          </DialogActions>
        </Dialog>
      )}
      {Object.keys(snakeBarProps).length > 0 && (
        <CustomSnackbar
          {...snakeBarProps}
          setSnakeBarProps={setSnakeBarProps}
        />
      )}
    </AuthLayout>
  );
};

export default User;
